body {
  margin: 0;
  line-height: 1.5;
  font-family: 'Manulife JH Sans', 'Open Sans', 'Arial', 'Helvetica', sans-serif;
  font-weight: 300;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}
